import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import { Link } from 'gatsby-plugin-react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Lazy } from 'swiper';

import { useLocale } from '@app/hooks';
import { Layout } from '@app/components';
import Icons from '@app/icons';

import slide1 from '@app/images/slides/1.png';
import slide2 from '@app/images/slides/2.jpg';
import slide3 from '@app/images/slides/3.jpg';
import slide4 from '@app/images/slides/4.jpg';
import serdarBoraBayraktarogluImage from '@app/images/serdar-bora-bayraktaroglu.jpg';

import '@app/styles/pages/index.scss';

const IndexPage = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Index',
  };
  const { t } = useLocale();

  return (
    <Layout page="home">
      <Helmet title={t('labels.pageTitle', SCOPE_OPTIONS)} />
      <Swiper
        modules={[Autoplay, Pagination, Lazy]}
        slidesPerView={1}
        loop
        lazy
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        className="slider h-screen"
      >
        <SwiperSlide>
          <Link to="/ameliyatsiz-yuz-genclestirmede-mucize-yontemler" className="slider__item">
            <img
              className="slider__item-img swiper-lazy"
              data-src={slide1}
              alt={t('slides.slider1.title', SCOPE_OPTIONS)}
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            <div className="slider__item-texts">
              <div className="container mx-auto px-1.5">
                <h2 className="slider__item-title">
                  <span>{t('slides.slider1.title', SCOPE_OPTIONS)}</span>
                </h2>
              </div>
              <div className="flex items-center">
                <h3 className="slider__item-desc">
                  {t('slides.slider1.description', SCOPE_OPTIONS)}
                </h3>
                <Link to="/profound-rf" className="slider__item-btn">
                  <Icons.ChevronRight />
                </Link>
              </div>
              <h4 className="slider__item-subtitle">
                {t('slides.slider1.subtitle', SCOPE_OPTIONS)}
              </h4>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/nose-aesthetics" className="slider__item">
            <img
              className="slider__item-img swiper-lazy"
              data-src={slide2}
              alt={t('slides.slider2.title', SCOPE_OPTIONS)}
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            <div className="slider__item-texts">
              <div className="container mx-auto px-1.5">
                <h2 className="slider__item-title">
                  <span>{t('slides.slider2.title', SCOPE_OPTIONS)}</span>
                </h2>
              </div>
              <div className="flex items-center">
                <h3 className="slider__item-desc">
                  {t('slides.slider2.description', SCOPE_OPTIONS)}
                </h3>
                <Link to="/nose-aesthetics" className="slider__item-btn">
                  <Icons.ChevronRight />
                </Link>
              </div>
              <h4 className="slider__item-subtitle">
                {t('slides.slider2.subtitle', SCOPE_OPTIONS)}
              </h4>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/breast-augmentation" className="slider__item">
            <img
              className="slider__item-img swiper-lazy"
              data-src={slide3}
              alt={t('slides.slider3.title', SCOPE_OPTIONS)}
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            <div className="slider__item-texts">
              <div className="container mx-auto px-1.5">
                <h2 className="slider__item-title">
                  <span>{t('slides.slider3.title', SCOPE_OPTIONS)}</span>
                </h2>
              </div>
              <div className="flex items-center">
                <h3 className="slider__item-desc">
                  {t('slides.slider3.description', SCOPE_OPTIONS)}
                </h3>
                <Link to="/breast-augmentation" className="slider__item-btn">
                  <Icons.ChevronRight />
                </Link>
              </div>
              <h4 className="slider__item-subtitle">
                {t('slides.slider3.subtitle', SCOPE_OPTIONS)}
              </h4>
            </div>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/post-bariatric-surgery" className="slider__item">
            <img
              className="slider__item-img swiper-lazy"
              data-src={slide4}
              alt={t('slides.slider4.title', SCOPE_OPTIONS)}
            />
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            <div className="slider__item-texts">
              <div className="container mx-auto px-1.5">
                <h2 className="slider__item-title">
                  <span>{t('slides.slider4.title', SCOPE_OPTIONS)}</span>
                </h2>
              </div>
              <div className="flex items-center">
                <h3 className="slider__item-desc">
                  {t('slides.slider4.description', SCOPE_OPTIONS)}
                </h3>
                <Link to="/post-bariatric-surgery" className="slider__item-btn">
                  <Icons.ChevronRight />
                </Link>
              </div>
              <h4 className="slider__item-subtitle">
                {t('slides.slider4.subtitle', SCOPE_OPTIONS)}
              </h4>
            </div>
          </Link>
        </SwiperSlide>
      </Swiper>

      <section className="py-20">
        <div className="container mx-auto px-1.5 lg:px-48">
          <div className="text-center">
            <h3 className="text-5xl font-bold text-gray-700">{t('labels.title', SCOPE_OPTIONS)}</h3>
          </div>
          <div className="grid grid-cols-1 gap-8 justify-items-center lg:justify-items-start lg:grid-cols-2 mt-12">
            <img
              src={serdarBoraBayraktarogluImage}
              className="mb-10"
              alt="Serdar Bora Bayraktaroğlu"
            />

            <div className="text-gray-700">
              <p
                className="mb-6"
                dangerouslySetInnerHTML={{ __html: t('descriptions.about', SCOPE_OPTIONS) }}
              />
              <p
                className="mb-6"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.briefDescription', SCOPE_OPTIONS),
                }}
              />
              <p
                className="mb-6"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.info', SCOPE_OPTIONS),
                }}
              />

              <div className="flex flex-col lg:flex-row items-center mt-10">
                <div>
                  <h3 className="section-title">{t('labels.title', SCOPE_OPTIONS)}</h3>
                  <p className="mt-2">{t('labels.ask', SCOPE_OPTIONS)}</p>
                </div>
                <div className="mt-10 lg:mt-0">
                  <Link to="/contact" className="circle-button">
                    <span>{t('actions.fillForm', SCOPE_OPTIONS)}</span>
                    <Icons.Check />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-200">
        <div className="container mx-auto px-1.5">
          <div className="text-center">
            <h3 className="text-5xl font-bold text-gray-700">
              {t('labels.noseAesthetics', SCOPE_OPTIONS)}
            </h3>
          </div>

          <div className="grid grid-cols-1 justify-items-center gap-8 lg:justify-items-start lg:grid-cols-2 mt-12">
            <img src={slide2} className="mb-10" alt="Burun Estetiği" />

            <div className="text-gray-700">
              <p
                className="mb-6 text-2xl"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.noseAestheticsDescription', SCOPE_OPTIONS),
                }}
              />
              <p
                className="mb-6 text-xl"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.noseAestheticsLongDescription', SCOPE_OPTIONS),
                }}
              />

              <div className="flex flex-col lg:flex-row items-center mt-10">
                <Link
                  to="/rhinoplasty"
                  className="inline-block bg-primary py-2.5 px-5 rounded text-white font-bold hover:bg-red-800 cursor-pointer transition-all duration-300"
                >
                  {t('actions.noseAestheticsButton', SCOPE_OPTIONS)}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-1.5">
          <div className="text-center">
            <h3 className="text-5xl font-bold text-gray-700">
              {t('labels.mammaplasty', SCOPE_OPTIONS)}
            </h3>
          </div>

          <div className="grid grid-cols-1 justify-items-center gap-8 lg:justify-items-start lg:grid-cols-2 mt-12">
            <div className="text-gray-700">
              <p
                className="mb-6 text-xl"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.mammaplastyFirstDescription', SCOPE_OPTIONS),
                }}
              />
              <p
                className="mb-6 text-xl"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.mammaplastySecondDescription', SCOPE_OPTIONS),
                }}
              />

              <div className="flex flex-col lg:flex-row items-center mt-10 justify-end">
                <Link
                  to="/breast-augmentation"
                  className="inline-block bg-primary py-2.5 px-5 rounded text-white font-bold hover:bg-red-800 cursor-pointer transition-all duration-300"
                >
                  {t('actions.mammaplastyButton', SCOPE_OPTIONS)}
                </Link>
              </div>
            </div>

            <img src={slide3} className="mb-10" alt="Burun Estetiği" />
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-200">
        <div className="container mx-auto px-1.5">
          <div className="text-center">
            <h3 className="text-5xl font-bold text-gray-700">
              {t('labels.postBariatricSurgery', SCOPE_OPTIONS)}
            </h3>
          </div>

          <div className="grid grid-cols-1 justify-items-center gap-8 lg:justify-items-start lg:grid-cols-2 mt-12">
            <img src={slide4} className="mb-10" alt="Burun Estetiği" />

            <div className="text-gray-700">
              <p
                className="mb-6 text-xl"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.postBariatricSurgeryFirstDescription', SCOPE_OPTIONS),
                }}
              />
              <p
                className="mb-6 text-xl"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.postBariatricSurgerySecondDescription', SCOPE_OPTIONS),
                }}
              />

              <div className="flex flex-col lg:flex-row items-center mt-10">
                <Link
                  to="/post-bariatric-surgery"
                  className="inline-block bg-primary py-2.5 px-5 rounded text-white font-bold hover:bg-red-800 cursor-pointer transition-all duration-300"
                >
                  {t('actions.postBariatricSurgeryButton', SCOPE_OPTIONS)}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-1.5">
          <div className="text-center">
            <h3 className="text-5xl font-bold text-gray-700">
              {t('labels.profoundRf', SCOPE_OPTIONS)}
            </h3>
          </div>

          <div className="grid grid-cols-1 justify-items-center gap-8 lg:justify-items-start lg:grid-cols-2 mt-12">
            <div className="text-gray-700">
              <p
                className="mb-6 text-xl"
                dangerouslySetInnerHTML={{
                  __html: t('descriptions.profoundRFDescription', SCOPE_OPTIONS),
                }}
              />

              <div className="flex flex-col lg:flex-row items-center mt-10 justify-end">
                <Link
                  to="/profound-rf"
                  className="inline-block bg-primary py-2.5 px-5 rounded text-white font-bold hover:bg-red-800 cursor-pointer transition-all duration-300"
                >
                  {t('actions.profoundRfButton', SCOPE_OPTIONS)}
                </Link>
              </div>
            </div>

            <img src={slide1} className="mb-10" alt="Burun Estetiği" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
